import 'core-js/es/object/assign';

// uncomment to import all of LEGOS
import * as LEGOS from '@kmx/legos';
import { KMXTextField } from '@kmx/legos-text-field/index';
import { KMXSnackbar } from '@kmx/legos-snackbar/index';
import { KMXDialog } from '@kmx/legos-dialog/index';


function textFieldInit() {
    const demoTextFieldSelector = '.kmx-text-field:not([data-demo-no-auto-js])';
    const allTextFields = document.querySelectorAll(demoTextFieldSelector);

    allTextFields.forEach(element => {
        const thisTextField = new KMXTextField(element);
    });
}

function showSnackbar(id, val) {
    var message = { message: val };
    const snackbarElement = document.querySelector(id);
    const snackbar = new KMXSnackbar(snackbarElement);
    snackbar.show(message);
}

function showDialog(id) {
    const dialogElement = document.querySelector(id);
    const dialog = new KMXDialog(dialogElement);
    dialog.open();
}

// Example of explicit named imports:
// (when running webpack in production mode, other modules will be tree shaken out)
import { BREAKPOINTS } from './breakpoints';

// App SCSS imports here:
import './yourApp.scss';

// IIFE wrapper
((dsa) => {
    textFieldInit();
    dsa.showSnackbar = showSnackbar;
    dsa.showDialog = showDialog;
    window.Dsa = dsa;
})(window.Dsa || {});
